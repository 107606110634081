<script>
  let name = "gy-slideshow";
  export let album = "https://www.geophysik.uni-muenchen.de/en/slideshow/album.json";
  let images =[];
  let isLoading = false;
  let w;
	let h;
  let image;

  import { onMount } from 'svelte';

  isLoading = true;
  let getImages = fetch(album)
    .then(res => {
      if (!res.ok) {
        throw new Error("Failed!");
      }
      return res.json();
    })
    .then(data => {
      isLoading = false;
      images = Object.values(data);
			return images; 
    })
    .catch(err => {
      isLoading = false;
      console.log(err);
    });

  let imageShowIndex = 0;

  $: console.log(imageShowIndex)

  $: image = images[imageShowIndex];

  const prevSlide = () => {
    if (imageShowIndex === 0){
      imageShowIndex = images.length-1;
    } else {
      imageShowIndex -= 1;
    }
    
  }

  const nextSlide = () => {
    if (imageShowIndex === images.length-1){
      imageShowIndex = 0;
    } else {
      imageShowIndex += 1;
    }
  }

  const gotToSlide = (idNumber) => imageShowIndex = idNumber;

  const showSlides = () => {
		nextSlide();
		setTimeout(showSlides, 8000);
	}
	
	onMount( () => {
			setTimeout(showSlides, 8000);
	} );
</script>

<style>
  .svelte-app-wrapper {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
  }

  .main {
    width: 100%;
    margin: 0%;
    padding: 0%;
    height: auto;
    align-items: center;
  }

  /* Position the image container (needed to position the left and right arrows) */
  .img-container {
    position: relative;
    margin: 0%;
    padding: 0%;
  }

  .mySlides{
    margin: 0%;
    padding: 0%;
  }

  .thumbnails-row:after {
    content: "";
    display: table;
    clear: both;
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: black;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  img{
    width: 100%;
    height: auto;
    margin: 0%;
    padding: 0%;
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    /* position: absolute; */
    /* top: 40%; */
    width: auto;
    padding: 10px;
    /* margin-top: -50px; */
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  .prev, .next, a:link, a:hover, a:visited, a:visited{ text-decoration: none; }

  /* Position the "next button" to the right */
  .next {
    /* right: 0; */
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: #00b053;
  }

  /* Container for image text */
  .caption-container {
    margin: 0%;
    width: 100%;
    height:auto;
    display: flex;
    align-items:center;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    text-align: center;
    background-color: #00b053;
    /* padding: 2px 16px; */
    color: white;
  }

  /* Six columns side by side */
  .thumbnail-container {
    float: left;
    margin: 0%;
    padding: 0%;    
    }

    /* Add a transparency effect for thumnbail images */
    .sample {
    opacity: 0.4;
    }

    .sample:hover {
    opacity: 1;
    }

    .active {
    opacity: 1;
    }

</style>

<svelte:options tag="gy-slideshow" />

<div class="svelte-app-wrapper">
  <div class="main" bind:clientWidth={w} bind:clientHeight={h}>
    <!-- Container for the image gallery -->
    {#await getImages}
    <p>Loading...</p>
    {:then images}
      <div class="img-container">
  
        <div class="mySlides">
          <div class="numbertext">{image.id +1} / {images.length}</div>
          <img src={image.imgUrl} title={image.attribute} alt={image.imgName} style="width: 100%; height:auto;">
        </div>
  
      </div>
  

      <!-- Image text and Next and previous buttons -->
      <div class="caption-container" style="width:{w}px;">
        <a href="#arrowL" class="prev" on:click={prevSlide}>&#10094;</a>
        <p id="caption">{image.imgName}</p>
        <a href="#arrowR"  class="next" on:click={nextSlide}>&#10095;</a>
      </div>
  
  
      <div class="thumbnails-row" style="width:{w}px;">
      {#each images as {id, imgUrl, attribute}}
        <!-- Thumbnail images -->
        <div class="thumbnail-container">
          <img class="sample cursor" 
              class:active={id === imageShowIndex} 
              src="{imgUrl} " 
              style="height:{w/images.length}px; width:{w/images.length}px;"
              alt={attribute} 
              on:click={() => gotToSlide(id)}>
        </div>
        {/each}
  
      </div>
      {:catch error}
        <p>{error.message}</p>
      {/await}
  </div>
</div>
